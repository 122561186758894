import * as styles from './Documentation.module.scss';

import React, { memo, useEffect, useState } from 'react';
import cn from 'classnames';

import { items } from './info';
import icon from 'src/assets/images/document.svg';
import Select from 'src/components/ui/Select';

const Documentation = () => {
  const [showItem, setShowItem] = useState(items[0]);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    window.innerWidth < 1024 && setMobile(true)
    window.addEventListener('resize', () => {
      window.innerWidth < 1024 ? setMobile(true) : setMobile(false)
    })
  }, []);

  return (
    <div className={styles.documentation}>
      <h1 className={styles.documentation__title}>
        Сведения об образовательной организации
      </h1>
      <div className={styles.documentation__content}>
        <div className={styles.documentation__menu}>
          {mobile ?
            <>
              <h2 className={styles.documentation__subtitle}>
                Выберите интересующие сведения
              </h2>
              <Select
                items={items}
                selectedItem={showItem}
                setSelectedItem={setShowItem}
              />
            </>
            :
            items.map((item) =>
              <button
                className={cn(
                  styles.documentation__item,
                  styles.documentation__item_menu,
                  item.id === showItem.id && styles.documentation__item_selected
                )}
                key={item.id}
                onClick={() => setShowItem(item)}
              >
                {item.title}
              </button>
            )
          }
        </div>
        <div className={styles.documentation__info}>
          {!mobile &&
            <h2 className={styles.documentation__subtitle}>{showItem.title}</h2>
          }
          {showItem.content.map((item) => (
            <div className={styles.documentation__item} key={item.title}>
              {item.title &&
                <p className={styles.documentation__entry}>
                  {item.title}
                </p>
              }
              {item.text && item.text.map((text) =>
                <p className={styles.documentation__text} key={text}>{text}</p>
              )}
              {item.document &&
                <div className={styles.documentation__document} key={item.document.name}>
                  <img className={styles.documentation__document_icon} src={icon} alt="doc" />
                  <a
                    className={cn(styles.documentation__document_name, styles.documentation__text)}
                    href={`/${item.document.link}`}
                    target='_blank'
                  >
                    {item.document.name}
                  </a>
                </div>
              }
              {item.titleWithLink &&
                <p className={styles.documentation__entry}>
                  {item.titleWithLink.text}
                  <a className={styles.documentation__link} href={item.titleWithLink.link}>
                    {item.titleWithLink.textWithLink}
                  </a>
                </p>
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default memo(Documentation);
