import * as styles from './Select.module.scss';

import cn from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { infoType } from 'src/types/documentation';

type SelectProps = {
  items: infoType[],
  selectedItem: infoType,
  setSelectedItem: (param: infoType) => void,
}

const Select: FC<SelectProps> = ({ items, selectedItem, setSelectedItem }) => {
  const [optionsShown, setOptionsShown] = useState<boolean>(false);
  const refElement = useRef<HTMLDivElement>(null);

  const toggleList = useCallback(() => {
    setOptionsShown((prev) => !prev);
  }, []);

  useEffect(() => {
    const closeList = (event: MouseEvent) => {
      const el = event.target as HTMLDivElement;
      if (!refElement.current?.contains(el)) setOptionsShown(false)
    };

    document.addEventListener('click', closeList)
    return() => document.removeEventListener('click', closeList)
  }, []);

  return (
    <div className={cn(styles.select, optionsShown && styles.select_openList)}>
      <div className={styles.select__block} onClick={toggleList} ref={refElement}>
        <p className={cn(styles.select__selectedItem)}>
          {selectedItem.title}
        </p>
        <div className={styles.select__open} />
        <div className={styles.select__items}>
          {items.map((option) => (
            <button
              onClick={() => setSelectedItem(option)}
              key={option.id}
              className={cn(styles.select__item, option.id === selectedItem.id && styles.select__item_selected)}
            >
              {option.title}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
};


export default React.memo(Select);
