import * as React from 'react';
import Layout from 'src/components/Layout';
import Header from "src/components/Header";
import Footer from 'src/components/Footer';
import Documentation from 'src/components/Documentation';

const DocumentationPage = () => (
  <Layout>
    <Header fixColor withNavigateBack />
    <Documentation />
    <Footer />
  </Layout>
);

export default DocumentationPage;
