import { infoType } from "src/types/documentation";

export const items: infoType[] = [
  {
    title: 'Основные сведения',
    id: 1,
    content: [{
      title: 'о полном и сокращенном наименовании образовательной организации',
      text: [
        'Общество с ограниченной ответственностью “ИНТЕЛСИ”',
        'ООО “ИНТЕЛСИ”'
      ],
      document: undefined
    }, {
      title: 'о дате создания образовательной организации',
      text: ['09.02.2022'],
      document: undefined
    }, {
      title: 'об учредителе (учредителях) образовательной организации',
      text: ['Фетюхин Николай Сергеевич'],
      document: undefined
    }, {
      title: 'о наименовании представительств и филиалов образовательной организации (в том числе, находящихся за пределами Российской Федерации)',
      text: ['представительства и филиалы отсутствуют'],
      document: undefined
    }, {
      title: 'о месте нахождения образовательной организации, ее представительств и филиалов',
      text: ['432044 г. Ульяновск, ул. Кольцевая д.33'],
      document: undefined
    }, {
      title: 'о режиме и графике работы образовательной организации, ее представительств и филиалов',
      text: ['с понедельника по пятницу с 09.00 до 18.00'],
      document: undefined
    }, {
      title: 'о контактных телефонах образовательной организации, ее представительств и филиалов',
      text: ['8(422) 24-01-62'],
      document: undefined
    }, {
      title: 'об адресах электронной почты образовательной организации, ее представительств и филиалов',
      text: ['mail@mstagency.ru'],
      document: undefined
    }, {
      title: 'об адресах официальных сайтов представительств и филиалов образовательной организации (при наличии) или страницах в информационно-телекоммуникационной сети "Интернет"',
      text: ['https://growheads.ru/'],
      document: undefined
    }, {
      title: 'о местах осуществления образовательной деятельности, в том числе не указанных в приложении к лицензии (реестре лицензий) на осуществление образовательной деятельности',
      text: ['отсутствуют'],
      document: undefined
    }]
  },
  {
    // НЕ ГОТОВО
    title: 'Структура и органы управления образовательной организацией',
    id: 2,
    content: [{
      title: 'о структуре и об органах управления образовательной организации с указанием наименований структурных подразделений (органов управления)',
      text: ['Директор Фетюхин Николай Сергеевич'],
      document: undefined
    },
    {
      title: undefined,
      text: ['Структура'],
      document: undefined
    },
    {
      title: 'Администрация',
      text: undefined,
      document: undefined
    }, {
      title: 'Коммерческий департамент',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел обучения',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел интернет-маркетинга',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел диджитал-контента',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел бэкенд-разработки',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел фронтенд-разработки',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел качества',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел мобильной разработки',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел управления проектами',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел дизайна',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел по работе с персоналом',
      text: undefined,
      document: undefined
    }, {
      title: 'Отдел продаж',
      text: undefined,
      document: undefined
    }, {
      title: 'о местах нахождения структурных подразделений (органов управления) образовательной организации',
      document: undefined,
      text: ['Место нахождения всех структурных подразделений и органов управления – 432044 г. Ульяновск, ул. Кольцевая д.33']
    }, {
      title: 'об адресах официальных сайтов в информационно-телекоммуникационной сети "Интернет" структурных подразделений (органов управления) образовательной организации',
      document: undefined,
      text: ['https://growheads.ru/']
    }, {
      title: 'об адресах электронной почты структурных подразделений (органов управления) образовательной организации',
      document: undefined,
      text: ['mail@mstagency.ru']
    }]
  },
  {
    title: 'Документы',
    id: 3,
    content: [{
      title: undefined,
      text: undefined,
      document: {
        name: 'Устав образовательной организации',
        link: 'Ustav.pdf'
      }
    }, {
      title: 'локальные нормативные акты образовательной организации по основным вопросам организации и осуществления образовательной деятельности, в том числе регламентирующие:',
      text: undefined,
      document: undefined
    }, {
      title: undefined,
      text: undefined,
      document: {
        name: 'Правила приема обучающихся',
        link: 'rulesOfAdmission.pdf'
      }
    }, {
      title: undefined,
      text: undefined,
      document: {
        name: 'Режим занятий обучающихся',
        link: 'aboutTheModeOfClasses.pdf'
      }
    }, {
      title: undefined,
      text: undefined,
      document: {
        name: 'Формы, периодичность и порядок текущего контроля успеваемости и промежуточной аттестации обучающихся',
        link: 'aboutCertificate.pdf'
      }
    }, {
      title: undefined,
      text: undefined,
      document: {
        name: 'Порядок и основания перевода, отчисления и восстановления обучающихся',
        link: 'translationAndRestoration.pdf'
      }
    }, {
      document: {
        name: 'Политика использования cookie',
        link: 'cookie_policy.docx'
      }
    }, {
      document: {
        name: 'Политика персональных данных',
        link: 'personal_data_policy.docx'
      }
    }, {
      document: {
        name: 'Пользовательское соглашение',
        link: 'user_agreement.docx'
      }
    }, {
      document: {
        name: 'Согласие на обработку данных для посетителей сайта',
        link: 'consent_to_data_processing.docx'
      }
    }]
  },
  {
    title: 'Образование',
    id: 4,
    content: [{
      title: 'а) о реализуемых образовательных программах, в том числе о реализуемых адаптированных образовательных программах, с указанием в отношении каждой образовательной программы:',
      document: undefined,
      text: undefined
    }, {
      title: 'форм обучения;',
      text: ['дистанционная'],
      document: undefined,
    }, {
      title: 'нормативного срока обучения;',
      document: undefined,
      text: ['Учебный процесс осуществляется в течение всего календарного года']
    }, {
      title: 'срока действия государственной аккредитации образовательной программы (при наличии государственной аккредитации), общественной, профессионально-общественной аккредитации образовательной программы (при наличии общественной, профессионально-общественной аккредитации)',
      document: undefined,
      text: ['отсутствует ']
    }, {
      title: 'языка(х), на котором(ых) осуществляется образование (обучение);',
      document: undefined,
      text: ['Обучение ведётся на русском языке']
    }, {
      title: 'учебных предметов, курсов, дисциплин (модулей), предусмотренных соответствующей образовательной программой;',
      document: undefined,
      text: [
        'Учебный модуль - “Введение в профессию”',
        'Учебный модуль - “Исследование и анализ”',
        'Учебный модуль - “Проектирование”',
        'Учебный модуль - “Прототипирование”'
      ]
    }, {
      title: 'об использовании при реализации образовательной программы электронного обучения и дистанционных образовательных технологий;',
      document: undefined,
      text: [
        'При реализации программ используются электронное обучение и дистанционные образовательные технологии. В частности используются следующие основные технологии организации учебного процесса:',
        '• онлайн-платформа GH;',
        '• вебинары (онлайн режим);',
        '• тестирование'
      ]
      }, {
      titleWithLink: {
          text: 'б) об описании образовательной программы с приложением образовательной программы в форме электронного документа или в виде активных ссылок, непосредственный переход по которым позволяет получить доступ к страницам Сайта, содержащим информацию, указанную в',
          textWithLink: ' подпункте "б" подпункта 3.4 пункта 3 настоящих Требований',
          link: 'https://docs.cntd.ru/document/565780511#7DC0K7',
      },
      title: undefined,
      document: undefined,
      text: undefined
    }, {
      title: 'в) о численности обучающихся, в том числе:',
      document: undefined,
      text: undefined
    }, {
      title: 'о численности обучающихся за счет бюджетных ассигнований федерального бюджета (в том числе с выделением численности обучающихся, являющихся иностранными гражданами);',
      document: undefined,
      text: [
        'Численность обучающихся по реализуемым образовательным программам за счет бюджетных ассигнований, федерального бюджета, бюджетов субъектов РФ, местных бюджетов — 0 человек',
        'Численность обучающихся иностранных граждан — 0 человек'
      ]
    }, {
      title: 'Образовательные организации, реализующие общеобразовательные программы, дополнительно указывают наименование образовательной программы.',
      document: undefined,
      text: ['дополнительная общеобразовательная общеразвивающая программа технической направленности «UX-дизайн. Вводный курс»']
    }]
  },
  {
    title: 'Руководство. Педагогический (научно-педагогический) состав',
    id: 5,
    content: [{
      title: 'о руководителе образовательной организации, в том числе:',
      text: ['Директор Фетюхин Николай Сергеевич', 'mail@mstagency.ru'],
      document: undefined,
    }, {
      title: 'г) о персональном составе педагогических работников каждой реализуемой образовательной программы в форме электронного документа или в виде активных ссылок, непосредственный переход по которым позволяет получить доступ к страницам Сайта, содержащим информацию, указанную в подпункте "г" подпункта 3.6 пункта 3 настоящих Требований, в том числе:',
      document: undefined,
      text: undefined
    }, {
      title: undefined,
      document: undefined,
      text: ['Образовательный процесс в ООО «ИНТЕЛСИ» реализуется педагогическими работниками и работниками, отнесенными к профессорско-преподавательскому составу, соответствующими требованиям законодательства в части квалификации, стажа работы и уровня образования, и не имеющими ограничений для занятия педагогической деятельностью.']
    }]
  },
  {
    title: 'Материально-техническое обеспечение и оснащенность образовательного процесса',
    id: 6,
    content: [{
      title: undefined,
      document: undefined,
      text: [
        'В ООО «ИНТЕЛСИ» созданы условия для функционирования электронной информационно-образовательной среды, включающей в себя:',
        '• электронные информационные ресурсы,',
        '• электронные образовательные ресурсы,',
        '• совокупность соответствующих информационных технологий, телекоммуникационных технологий, технологических средств.'
      ]
    }, {
      title: undefined,
      document: undefined,
      text: [
        'Электронная образовательная среда ООО «ИНТЕЛСИ» обеспечивает освоение обучающимися образовательных программ в полном объеме независимо от их места нахождения.',
        'Дистанционное обучение реализуется на платформе https://growheads.ru/'
      ]
    }, {
      title: undefined,
      document: undefined,
      text: ['Обучающийся получает полный доступ к электронным материалам курса (текст курса, презентации, видеолекции, методические материалы и т.п.) после оплаты его стоимости.']
    }]
  },
  {
    title: 'Платные образовательные услуги',
    id: 8,
    content: [{
      title: undefined,
      text: undefined,
      document: {
        name: 'В том числе образец договора об оказании платных образовательных услуг',
        link: 'Сontract.pdf'
      }
    },
    {
      title: undefined,
      text: undefined,
      document: {
        name: 'Положение об оказании платных обр. услуг',
        link: 'Position.pdf'
      }
    },
    {
      title: undefined,
      text: undefined,
      document: {
        name: 'Приказ',
        link: 'Order.pdf'
      }
    }]
  },
  {
    title: 'Финансово-хозяйственная деятельность',
    id: 9,
    content: [{
      title: undefined,
      document: undefined,
      text: ['Финансовое обеспечение не осуществляется за счет бюджетных ассигнований федерального бюджета, бюджетов субъектов Российской Федерации, местных бюджетов.']
    }]
  },
  {
    title: 'Вакантные места для приема (перевода) обучающихся',
    id: 10,
    content: [{
      title: undefined,
      document: undefined,
      text: ['Набор слушателей на обучение по образовательным программам ведется в течение всего календарного года.']
    }, {
      title: undefined,
      document: undefined,
      text: ['В ООО «ИНТЕЛСИ» прием обучающихся ведется в соответствии с заключаемыми договорами об оказании платных образовательных услуг без ограничений.']
    }, {
      title: undefined,
      document: undefined,
      text: ['Квоты и контрольные цифры не установлены.']
    }, {
      title: undefined,
      document: undefined,
      text: [
        '• Количество вакантных мест для приема (перевода) за счет бюджетных ассигнований федерального бюджета отсутствуют;',
        '• Количество вакантных мест для приема (перевода) за счет бюджетных ассигнований бюджетов субъекта Российской Федерации отсутствуют;',
        '• Количество вакантных мест для приема (перевода) за счет бюджетных ассигнований местных бюджетов отсутствуют.'
      ]
    }]
  },
  {
    title: 'Доступная среда',
    id: 11,
    content: [{
      title: undefined,
      document: undefined,
      text: ['Реализация образовательных программ в помещениях ООО «ИНТЕЛСИ» не предусмотрена, вся образовательная деятельность осуществляется с использованием дистанционных образовательных технологий.']
    }, {
      title: undefined,
      document: undefined,
      text: ['В ООО «ИНТЕЛСИ» обеспечивается доступ инвалидов и лиц с ограниченными возможностями к:']
    }, {
      title: undefined,
      document: undefined,
      text: [
        '• информационным системам и информационно-телекоммуникационным сетям,',
        '• электронным образовательным ресурсам.'
      ]
    }]
  },
  {
    title: 'Международное сотрудничество',
    id: 12,
    content: [{
      title: undefined,
      document: undefined,
      text: ['ООО «ИНТЕЛСИ» не имеет:']
    }, {
      title: undefined,
      document: undefined,
      text: [
        '• заключенных и планируемых к заключению договоров с иностранными и (или) международными организациями по вопросам образования и науки,',
        '• образовательных программ, имеющих международную аккредитацию.'
      ]
    }]
  },
]
