// extracted by mini-css-extract-plugin
export var documentation = "Documentation-module--documentation--3U-N9";
export var documentation__title = "Documentation-module--documentation__title--ATfvy";
export var documentation__content = "Documentation-module--documentation__content--2wPVe";
export var documentation__menu = "Documentation-module--documentation__menu--1SZeX";
export var documentation__item = "Documentation-module--documentation__item--3PuVl";
export var documentation__item_menu = "Documentation-module--documentation__item_menu--2G2R_";
export var documentation__item_selected = "Documentation-module--documentation__item_selected--2IVUG";
export var documentation__info = "Documentation-module--documentation__info--luoKD";
export var documentation__infoInner = "Documentation-module--documentation__infoInner--3ESBG";
export var documentation__subtitle = "Documentation-module--documentation__subtitle--1xn7R";
export var documentation__entry = "Documentation-module--documentation__entry--2uM8C";
export var documentation__text = "Documentation-module--documentation__text--2J07C";
export var documentation__document = "Documentation-module--documentation__document--3xBHA";
export var documentation__document_name = "Documentation-module--documentation__document_name--1JlDj";
export var documentation__document_icon = "Documentation-module--documentation__document_icon--2Xpcu";
export var documentation__link = "Documentation-module--documentation__link--2N8iC";